import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ViewEncapsulation,
  Input,
  OnChanges,
  SimpleChanges,
  ElementRef,
  Output,
  EventEmitter,
  Renderer2,
} from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { BookingService } from 'src/app/services/booking.service';
import { SessionList } from 'src/app/models/session';
import { BookingCartList } from 'src/app/models/booking';
import { CourseList } from 'src/app/models/course-interface';
import { CourseService } from 'src/app/services/course.service';
import { ActivatedRoute } from '@angular/router';
import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
@Component({
  selector: 'app-booking-session-list-new',
  templateUrl: './booking-session-list-new.component.html',
  styleUrls: ['./booking-session-list-new.component.scss']
})
export class BookingSessionListNewComponent implements OnInit, AfterViewInit, OnChanges {

  constructor(public apiService: ApiService, private modalRef: NgbModal, private router: Router, private bookingApiService: BookingService, private courseService: CourseService, private route: ActivatedRoute, ) {}
  goToMonth: string = `${new Date().getMonth()} ${new Date().getFullYear()}`
  items: number[] = Array(50).fill(0).map((_, i) => i + 1);
  getCurrentMonth: string = `${new Date().getMonth()} ${new Date().getFullYear()}`;
  calendarOptions: any;
  isCaam: boolean = false;
  allDone: boolean = false;
  dateChanged: any = new Date();
  weekDays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  currentMonthDisplay: Date | null = null;
  currentEndMonthDisplay: Date | null = null;
  isDoneLoad: boolean = false;
  allSessions: any = [];
  fullCalendarContainer!: ElementRef;
  courseId: string = '';
  selectedSessions: SessionList = {
    createdAt: '',
    s_course_id: '',
    s_deletedAt: null,
    s_end_date: '',
    s_name: '',
    s_start_date: '',
    s_status: false,
    s_venue: '',
    trainers: [],
    updatedAt: '',
    students: [],
    _id: '',
    isCheck: false,
    status: ''
  };

  cartList: BookingCartList = {
    createdAt: '',
    sessions: [],
    updatedAt: '',
    user: '',
    _id: ''
  }

  courseDetails: CourseList = {
    _id: '',
    c_course_id: '',
    c_course_name: '',
    c_course_description: '',
    c_course_category: null,
    c_training_method: null,
    c_course_duration: 0,
    courseModules: [],
    sessions: [],
    c_is_recommended: false,
    c_is_favourite: false,
    c_price_per_pax: 0,
    c_status: false,
    c_deletedAt: null,
    createdAt: '',
    updatedAt: '',
    __v: 0,
    c_no_of_pax: 0,
    c_hrdc_claimable: false,
    c_caam_certified: false,
  };
  trainers: string[] = [];

  ngAfterViewInit() {
  }

  ngOnChanges(){}

  ngOnInit() {
    this.route.params.subscribe(params => {
      const courseId = params['id'];
      this.courseId = courseId;
     this.getDetails()
    })

  }
  getDetails() {
    this.courseService.getCourseDetails(this.courseId).subscribe(res => {
      console.log('Course Details:', res);
      this.courseDetails = res;
    })
  }

}
