<div  class="container-fluid" style="width: 100% ; height: 100vh;overflow: auto; padding: 0%;">
  <div style=" display: flex; justify-content: center; align-items: center;">
    <img src="../../assets/gif/booking-session.gif" class="icon-booking-session" alt="success-img"/>
  </div>
  <div class="card-frame-text">
    Choose Session
  </div>
  <div class="card-session-text">
    Choose the perfect date for your session and secure your spot!
</div>
<div class="container" style="padding: 0px 120px; max-width: none !important;">

  <div class="row" style="margin: 20px 0px;">
    <div class="d-flex justify-content-between w-100">
<div class="col-3 d-flex flex-column" style="height: 100%; gap: 40px;">
  <div style="overflow: auto;">
    <app-mini-calendar (selectedMonthYear)="goToMonth = $event" *ngIf="allDone" [dateChanged]="dateChanged"></app-mini-calendar>
    </div>
  </div>
<div class="col-9 box-layout" style="background: linear-gradient(180deg, rgba(43, 60, 61, 0.12) 0%, rgba(117, 156, 159, 0.12) 100%); width: 70%;">
  <app-second-variation-calendar [currentId]="courseId" [getCurrentMonth]="goToMonth" (doneLoad)="allDone = $event.done; isCaam = $event.isCaam" (returnDateFromCalendar)="dateChanged = $event"></app-second-variation-calendar>
</div>
</div>
</div>
</div>
</div>

